import ImageWrapper from "../imageWrapper"
import React from "react"
import { Header } from "@common/header/headerUnit"
import Marquee from "react-fast-marquee"

// TODO - Component assumes multiples of 8.
// Could be made more generic when required.
export default function LogoGridUnit({
  headingText = null,
  gridIcons,
  logoSize = 100,
}) {
  // headingText      👉  OPTIONAL
  // gridIcons        👉  ARRAY OF OBJECT/S WITH imgPath PROP EX: [{imgPath: "./images/customer_logos/traeger.svg"}]
  // logoSize -> number

  const smallLogoSize = 0.8 * logoSize

  return (
    <>
      {headingText && (
        <Header.ShortHeader color="secondary" alignment="center">
          <span className="pb-8">{headingText}</span>
        </Header.ShortHeader>
      )}
      <div className=" justify-center lg:gap-6 xl:gap-8 flex-wrap hidden lg:flex items-center relative">
        {gridIcons?.map(({ imgPath }, index) => (
          <div
            key={index}
            className="relative"
            style={{ width: logoSize, height: logoSize }}
          >
            <ImageWrapper
              key={index}
              src={imgPath}
              fallbackUrl="/images/backupImages/no_img.png"
              blurDataURL="/images/backupImages/img-placeholder.svg"
              fill
              className="object-contain"
            />
          </div>
        ))}
      </div>
      <div className=" block lg:hidden items-center">
        <Marquee autoFill={true}>
          {gridIcons?.map(({ imgPath }, index) => (
            <div
              key={index}
              className=" mx-10 relative"
              style={{ width: smallLogoSize, height: smallLogoSize }}
            >
              <ImageWrapper
                src={imgPath}
                fallbackUrl="/images/backupImages/no_img.png"
                blurDataURL="/images/backupImages/img-placeholder.svg"
                fill
                className="object-contain"
              />
            </div>
          ))}
        </Marquee>
      </div>
    </>
  )
}
