import LinkButton from "@common/buttons/linkButton"
import { Header } from "@common/header/headerUnit"
import { useInView } from "react-intersection-observer"

export default function LargeFeatureUnit({
  shortHeader,
  header,
  description,
  actionText,
  actionLink,
  imgLeft,
}) {
  const { ref, inView } = useInView({
    threshold: 0.2,
  })
  return (
    <div
      ref={ref}
      className={
        inView
          ? imgLeft
            ? "animate-loadFadeFromRight"
            : "animate-loadFadeFromLeft"
          : "invisible"
      }
    >
      <Header variant="h3" alignment="left">
        <Header.ShortHeader>{shortHeader}</Header.ShortHeader>
        <Header.MainHeader>{header}</Header.MainHeader>
        <Header.Description>{description}</Header.Description>
      </Header>
      {actionText && actionLink && (
        <div className="mt-6">
          <LinkButton href={actionLink} variant="accent">
            {actionText}
          </LinkButton>
        </div>
      )}
    </div>
  )
}
