/* eslint-disable prefer-const */
import LargeFeatureUnit from "./largeFeatureUnit"
import PropTypes from "prop-types"

FeatureWithSideImage.propTypes = {
  image: PropTypes.string.isRequired,
  imgLeft: PropTypes.bool,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  shortHeader: PropTypes.string,
  actionText: PropTypes.string,
  actionLink: PropTypes.string,
  imageOverflow: PropTypes.bool,
  className: PropTypes.string,
}

FeatureWithSideImage.defaultProps = {
  imgLeft: false,
  imageOverflow: true, // stretches the images uptill its parents side edge (styled take)
}

//TODO: use imageWrapper
export default function FeatureWithSideImage({
  image,
  imgLeft = false,
  header,
  text,
  shortHeader,
  actionText,
  actionLink,
  imageOverflow = true,
}) {
  const imgClass = imgLeft == true ? "lg:left-0" : "lg:right-0"
  let imgContainerClass =
    imgLeft == true
      ? "pl-4 -mr-48 sm:pl-6 md:-mr-16"
      : "pr-4 -ml-48 sm:pr-6 md:-ml-16"

  return (
    <div className="relative bg-background overflow-hidden">
      <div className="lg:mx-auto lg:max-w-8xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div
          className={
            "px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 " +
            (imgLeft == true ? "" : "lg:col-start-2")
          }
        >
          <LargeFeatureUnit
            header={header}
            description={text}
            shortHeader={shortHeader}
            actionText={actionText}
            actionLink={actionLink}
            imgLeft={imgLeft}
          />
        </div>
        <div
          className={
            "mt-8 sm:mt-12 lg:mt-0 " + (imgLeft == true ? "" : "lg:col-start-1")
          }
        >
          <div
            className={"lg:px-0 lg:m-0 lg:relative lg:h-full " + imgContainerClass}
          >
            <img
              className={
                (imageOverflow
                  ? " w-full lg:absolute lg:h-full lg:w-auto lg:max-w-none "
                  : "w-full lg:absolute lg:h-full object-contain lg:max-w-none ") +
                imgClass
              }
              src={image}
              alt="Customer profile user interface"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
